
.mt-11 {
  margin-top: 11px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-66 {
  margin-top: 66px;
}

.mr-10 {
  margin-right: 10px;
}
.mr_12 {
  margin-right: 12px;
}
.mr_16 {
  margin-right: 16px;
}

.ml-3 {
  margin-left: 3px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-22 {
  margin-left: 22px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-32 {
  margin-left: 32px;
}
.ml-43 {
  margin-left: 43px;
}
.ml-46 {
  margin-left: 46px;
}
.editor-components-title {
  padding: 24px 16px;
  border-bottom: 1px solid #f2f4f6;
}

.edit-form-group {
  padding: 12px 16px;
}

.edit-segmentation-line {
  height: 1px;
  margin: 12px 16px;
  background-color: rgb(235, 237, 240);
}

.edit-form-detail-wrap-item {
  display: flex;
  justify-content: space-between;

  .edit-form-detail-wrap-item-desc-wrap {
    display: flex;

    .edit-form-detail-wrap-item-title {
      margin-right: 16px;
      font-size: 14px;
      color: #969799;
      line-height: 18px;
      white-space: nowrap;
      line-height: 32px;
    }

    .edit-form-detail-wrap-item-value {
      font-size: 14px;
      font-weight: 500;
      color: #323233;
      line-height: 18px;
      margin-right: 16px;
      line-height: 32px;
    }

    .sub-item-value-tips {
      margin-left: 4px;
      color: #969799;
    } 

  
  }

  .edit-form-detail-wrap-item-operate {  
    .ant-checkbox-wrapper {
      margin-top: 4px;
    }
  }
}

.edit-template-header {
  display: flex;
  align-items: center;

  .edit-template-lable {
    margin-right: 16px;
    font-size: 14px;
    color: #969799;
    line-height: 18px;
    white-space: nowrap;
  }

  .edit-template-value {
    font-size: 14px;
    font-weight: 500;
    color: #323233;
    line-height: 18px;
    margin-right: 16px;
  }

}

.edit-template-content {
  margin-top: 10px;
}

.edit-template-content-header {

  .edit-template-content-header-title {
    color: #323233;
    font-size: 14px;
    line-height: 18px;
  }

  .edit-template-content-header-sub-title {
    color: #969799;
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
  }
}



.common-input-division {
  // &::before {
  //   content: '-';
  //   position: absolute;
  //   left: -20px;

  // }
}