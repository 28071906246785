@font-face {
  font-family: 'iconfont';  /* Project id 2202668 */
  src: url('//at.alicdn.com/t/c/font_2202668_mkx4yjcq7v.woff2?t=1661331576536') format('woff2'),
       url('//at.alicdn.com/t/c/font_2202668_mkx4yjcq7v.woff?t=1661331576536') format('woff'),
       url('//at.alicdn.com/t/c/font_2202668_mkx4yjcq7v.ttf?t=1661331576536') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 下拉箭头
.icondown:before {
  content: "\e6f0";
}

// 页面icon
.iconpage::before {
  content: "\e630";
}

// 组件icon
.iconcomponent::before {
  content: '\e657';
}

// drag
.icondrag::before {
  content: '\e79e';
}

// 垃圾桶
.icondelete::before {
  content: '\e618';
}

// ➕
.iconadd::before {
  content: '\e627';
}

// left
.iconalignleft::before {
  content: '\e600';
}

// center
.iconaligncenter::before {
  content: '\e62b';
}

// 文本
.icontext::before {
  content: '\e615';
}

// 加粗 正常
.iconweightnormal::before {
  content: '\e69b';
}
// 加粗 
.iconweight::before {
  content: '\e69b';
  font-weight: 600;
}

//fontSize 小
.iconfontsizesmall::before {
  content: '\e601';
  font-size: 12px;
}

//fontSize 中
.iconfontsizemiddle::before {
  content: '\e601';
  font-size: 14px;
}

//fontSize 大
.iconfontlarge::before {
  content: '\e601';
  font-size: 16px;
}

// 关闭
.icondelete_close::before {
  content: '\e637';
}

// 空白的x
.iconclose::before {
  content: '\e61c';
}

// search
.iconsearch::before {
  content: '\e69d';
}

// tab 精选
.icontabhandpick::before {
  content: '\e683';
}

// tab 商品
.icontabgoods::before {
  content: '\e605';
}

// tab jd
.icontabjd::before {
  content: '\e684';
}

// 下箭头
.icondownshape::before {
  content: '\e685';
}

// 图片
.iconphotoswitch::before {
  content: '\e63e';
}

// 文字
.icontextswitch::before {
  content: '\e632';
}

// 固定
.iconfixed::before {
  content: '\e9ba';
}

// 左右滑动
.iconslider::before {
  content: '\e646';
}

// 图片一行一个
.iconimgadone::before {
  content: '\e613';
}

// 图片轮播
.iconimgadswiper::before {
  content: '\e706';
}

// 图片 大图横向滑动
.iconimgadlargeslider::before {
  content: '\e60c';
}

// 图片 小图横向滑动
.iconimgadsmallslider::before {
  content: '\e60b';
}

// 图片 导航横向滑动
.iconimgadnav::before {
  content: '\e616';
}

// 图片 热区
.iconimgadhotspot::before {
  content: '\e602';
}

// 首页
.iconhome::before {
  content: '\e68a';
}

// 待付款
.iconobligation::before {
  content: '\e60e';
}

// 已完成
.iconcompleted::before{
  content: '\e677';
}

// 售后
.iconaftersale::before{
  content: '\e678';
}

// 待收货
.icondelivered::before{
  content: '\e679';
}

// 我的
.iconmy::before {
  content: '\e675';
}

// 商品 一行两个
.icongoodstwo::before {
  content: '\e611';
}

// 商品 一行三个
.icongoodsthree::before {
  content: '\e612';
}

// 商品 详情列表
.icongoodsdetailslist::before {
  content: '\e648';
}

// 商品 一大两小
.icongoodsonetwo::before {
  content: '\e60f';
}

// 直角
.iconrightangle::before {
  content: '\e60a';
}

// 圆角
.iconfilletedcorner::before {
  content: '\e609';
}

// 填充
.iconfill::before {
  content: '\e825';
}

// 留白
.iconleavenote::before {
  content: '\e60d';
}

// .iconloseefficacy::before {
//   content: '\e756';
// }

// 阴影
.iconshadow::before {
  content: '\e64c';
}

// 常规
.iconshadowroutine::before {
  content: '\e62e';
}

.iconimg3-2::before {
  content: '\e61a';
}

.iconimg1-1::before {
  content: '\e61b';
}

.iconimg3-4::before {
  content: '\e61e';
}

.iconscrollnormal::before {
  content: '\e604';
}

.iconscrolltop::before {
  content: '\e606';
}

.iconsetting::before {
  content: '\e607';
}

.icontinypage::before {
  content: '\e6d2';
}

.iconindexpage::before {
  content: '\e69a';
}

// 一行四个
.iconyihangsige::before {
  content: '\e62a';
}

// 基础组件
.iconjichuzujian::before {
  content: '\e649';
}

// 官网组件
.iconguanwangzujian::before {
  content: '\e655';
}