@import './assets/less/theme.less';
@import './assets/less/common.less';
@import './assets/less/iconfont.less';
@import './assets/less/iconfont2.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


#root {
  height: 100%;
  overflow: auto;
  background-color: #f2f2f2;
  font-size: 14px;
  color: #333;

  // 默认样式
  p {
    margin-bottom: 0;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }
}


.ant-pagination-item-active a {
  color: #fff;
}
