.ant-app {
  .ant-pro-layout {
    .ant-pro-layout-content {
      padding: 16px;
      background-color: transparent;
    }
    .ant-pro-page-container {
      background-color: #fff;
      border-radius: 2px;

      .ant-tabs .ant-tabs-tab {
        padding-top: 0;
      }
    }
  }
}
