/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont2"; /* Project id 3809472 */
  src: url("//at.alicdn.com/t/c/font_3809472_4vbv7jeybsx.woff2?t=1670226265030")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_3809472_4vbv7jeybsx.woff?t=1670226265030")
      format("woff"),
    url("//at.alicdn.com/t/c/font_3809472_4vbv7jeybsx.ttf?t=1670226265030")
      format("truetype");
}

.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 1
.icon-1:before {
  content: "\e602";
}

// 2
.icon-2:before {
  content: "\e601";
}

// 3
.icon-3:before {
  content: "\e600";
}
